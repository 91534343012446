body {
  margin: 0;
  font-family: 'Futura';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button:hover {
      color: #fff;
      background-color: #0b5ed7;
      border-color: #0a58ca;
  }

.main {
  text-align: center;
}

.songs {
  margin: auto;
  width: 30%;
}

/* If mobile, make the width 80% */
@media only screen and (max-width: 600px) {
  .songs {
    width: 80%;
  }
}

.song {
  display: grid;
  grid-template-columns: 100px 10px 15vw 5px 15vw 5px 5vw;
  grid-template-rows: 50px 50px;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

.album-art {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.song-name {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.artist-name {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

.album-name {
  grid-column: 5 / 6;
  grid-row: 1 / 2;
}

.song-link {
  grid-column: 5 / 6;
  grid-row: 2 / 3;
}

.remove-button {
  grid-column: 7 / 8;
  grid-row: 1 / 3;
  font-size: 60px;
  font-weight: bold;
  color: #000;
  margin: 0;
  border: 0;
  padding: 0;
  background: hsl(216, 100, 50);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .remove-button {
    width: 30px;
    height: 30px;
    font-size: 30px;
  }
}
